import { Payment, Transaction } from "../models/payment-transaction.model";
import { PaymentStatus } from "../constants/expenses-payment-status.constant";
import { ExpensesDetail } from "../models/expenses-detail.model";
import { FormGroup } from "@angular/forms";
import { MAX_AMOUNT } from "../constants/globals.constant";
import { RegisteredPayment } from "../models/registered-payment.model";

export class PaymentComponent {
  public paymentStatus = PaymentStatus;
  constructor() {}

  public expenseDescriptionHeader(
    payment: ExpensesDetail,
    withoutPeriod?: boolean,
    notOwnerAssociationInformation: boolean = false,
    ownerAssociationAddress: string = ""
  ): string {
    const header = payment.location
      ? (!notOwnerAssociationInformation ? ownerAssociationAddress : payment.address) + " - " + payment.location
      : (!notOwnerAssociationInformation ? ownerAssociationAddress : payment.address) + " - " + payment.functionalUnitCode;

    return withoutPeriod ? header : header + " - " + payment.period;
  }

  public showFirstDueDate(expense: ExpensesDetail): boolean {
    return (
      expense.buildingExpensePaymentStatus === this.paymentStatus.NotExpired
    );
  }

  public existExpiredPayments(payments: Payment[]): boolean {
    return payments.some((payment: Payment) => {
      const dueDate =
        payment.buildingExpense.buildingExpensePaymentStatus === 0
          ? payment.buildingExpense.dueDate1
          : payment.buildingExpense.dueDate2;
      return (
        new Date(dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      );
    });
  }

  public isPaid(transaction: Transaction): boolean {
    return transaction.isPaid;
  }

  public findInvalidControls(form: FormGroup) {
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        return name;
      }
    }
  }

  public hasExceededAmount(transaction: Transaction): boolean {
    return transaction.payments.some((x) => x.amount > MAX_AMOUNT.value);
  }

  public isNotExpiredAndHasExceededAmount(transaction: Transaction): boolean {
    return (
      !this.existExpiredPayments(transaction.payments) &&
      this.hasExceededAmount(transaction)
    );
  }

  public isPartialPayment(
    expense: ExpensesDetail,
    payment: RegisteredPayment
  ): boolean {
    const paymentDate = new Date(payment.paymentDate).setHours(0, 0, 0, 0);
    const expenseDueDate1 = new Date(expense.dueDate1).setHours(0, 0, 0, 0);
    const expenseDueDate2 =
      expense.dueDate2 && new Date(expense.dueDate2).setHours(0, 0, 0, 0);
    return (
      (paymentDate <= expenseDueDate1 && payment.amount !== expense.amount1) ||
      (expenseDueDate2 &&
        paymentDate > expenseDueDate1 &&
        paymentDate <= expenseDueDate2 &&
        payment.amount !== expense.amount2)
    );
  }
}
